import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.headers = this.element.getElementsByClassName("tab-header");
    this.contents = this.element.getElementsByClassName("tab-content");

    Array.from(this.headers).forEach(header => header.addEventListener('click', this.onHeaderClick.bind(this)));
    this.headers[0].click();
  }

  onHeaderClick(event) {
    event.preventDefault();

    const header = event.target;
    const id = header.getAttribute("href").replace('#', '');

    Array.from(this.contents).forEach(tabContent => tabContent.classList.add('hidden'));
    Array.from(this.headers).forEach(header => header.classList.remove("bg-white"));

    document.getElementById(id).classList.remove("hidden");
    header.classList.add("bg-white");
  }
}
