import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    Array.from(this.element.querySelectorAll(".CodeRay")).forEach(codeEl => {
      const copyEl = document.createElement('a');
      ["absolute", "top-0", "right-0", "opacity-0"].map(klass => copyEl.classList.add(klass));
      copyEl.setAttribute("href", "#");
      copyEl.setAttribute("title", "Copy Code");
      copyEl.innerHTML = this.copyTemplate();
      copyEl.addEventListener('click', event => this.copy(event, codeEl));
      codeEl.append(copyEl);
      codeEl.addEventListener('mouseover', () => copyEl.classList.remove('opacity-0'));
      codeEl.addEventListener('mouseout', () => copyEl.classList.add('opacity-0'));
    });
  }

  copy(event, codeEl) {
    event.preventDefault();

    let input = document.createElement('textarea');
    input.innerHTML = codeEl.innerText;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    input.remove();

    let copied = document.createElement("div");
    copied.innerText = "copied";
    ["absolute", "top-0", "right-8", "text-gray-500"].map(klass => copied.classList.add(klass));
    codeEl.append(copied);
    setTimeout(() => copied.remove(), 1000);
  }

  copyTemplate() {
    return `<i class="far fa-copy fa-lg"></i>`;
  }
}
