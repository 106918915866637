import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["header", "body", "toggleIcon"];
  static values = {
    toggled: Boolean
  }

  connect() {
    this.titleElements = document.querySelectorAll(".post-container h2");
    if (this.titleElements.length <= 1) {
      return this.element.classList.add('hidden');
    }

    this.insertToc();
    this.headerTarget.addEventListener('click', this.toggle.bind(this));
    Array.from(this.bodyTarget.querySelectorAll("a[href]"))
      .forEach(link => link.addEventListener('click', (event) => this.scrollIntoView(event, link)));
  }

  toggle(event) {
    event.preventDefault();
    this.toggledValue = !this.toggledValue;

    if (this.toggledValue) {
      this.bodyTarget.classList.add("py-2");
      this.bodyTarget.classList.add("h-full");
      this.headerTarget.classList.add("border-b");
      this.toggleIconTarget.classList.add("transform");
    } else {
      this.bodyTarget.classList.remove("py-2");
      this.bodyTarget.classList.remove("h-full");
      this.headerTarget.classList.remove("border-b");
      this.toggleIconTarget.classList.remove("transform");
    }
  }

  scrollIntoView(event, link) {
    event.preventDefault();
    const header = document.querySelector(link.getAttribute('href'));
    header.scrollIntoView({ behavior: 'smooth' });
  }

  insertToc() {
    Array.from(this.titleElements).forEach(el => {
      const id = el.textContent.toLowerCase().replace(/\d+/, '').match(/\w+/g).join('-');
      const title = el.textContent;
      if (id && title) {
        el.setAttribute('id', id);
        const toc = document.createElement('div');
        toc.innerHTML = this.template({ id, title });
        this.bodyTarget.append(toc);
      }
    });
  }

  template({ id, title}) {
    return `
      <div class="p-2 px-4">
        <a href="#${id}" class="text-gray-500">
          <i class="fas fa-link mr-4 text-gray-400"></i><span>${title}</span>
        </a>
      </div>
      `;
  }
}
