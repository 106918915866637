import { Controller } from "stimulus"
import axios from "axios"

export default class extends Controller {

  connect() {
    document.querySelector('[href="#tab-preview"]').addEventListener('click', this.onPreviewClick.bind(this));
    this.previewContainer = this.element.querySelector(".preview-container");
  }

  async onPreviewClick() {
    const text = document.getElementById('post_content').value;

    if (this.text && this.text.length == text.length) return;

    const response = await axios.post('/posts/preview', { text: text });

    this.previewContainer.innerHTML = response.data;
    this.text = text;
  }
}
