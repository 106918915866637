import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    window.addEventListener('scroll', () => this.scrolled());
  }

  scrollToTop(event) {
    event.preventDefault();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth'});
  }

  scrolled() {
    if (window.scrollY >= 1000) {
      this.element.classList.remove("hidden");
    } else {
      this.element.classList.add("hidden");
    }
  }
}
