import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.submitBtn = this.element.querySelector('.submit-btn');
    this.contentEditor = this.element.querySelector(".trix-content");
    this.editorInput = this.element.querySelector("[name='comment[content]']");
    this.recaptchaTokenInput = document.getElementById('recaptcha-token');
    this.element.querySelector('.trix-button-group--file-tools').remove() // remove upload button
  }

  connect() {
    if (this.recaptchaTokenInput.dataset.verifiedRecaptcha != "true") {
      this.checkRecaptcha();
    }

    this.enableOrDisableSubmitBtn(!!this.editorInput.getAttribute("value")?.trim());

    this.contentEditor.addEventListener('keyup', () => {
      this.enableOrDisableSubmitBtn(!!this.contentEditor.innerText.trim());
    });
    this.ensureUniqueEditorInputName();

    this.element.addEventListener('submit', () => this.submitBtn.classList.add('disabled'));
  }

  editUser(event) {
    event.preventDefault();

    this.element.querySelector(".user-details").classList.add("hidden");
    this.element.querySelector(".user-form").classList.remove("hidden");
  }

  checkRecaptcha() {
    const recaptchaToken = this.recaptchaTokenInput.getAttribute('value')?.trim();
    const recaptchaFormTokenInput = this.element.querySelector('[name="recaptcha_form_token"]');

    if (!!recaptchaToken) {
      recaptchaFormTokenInput.setAttribute("value", recaptchaToken);
      this.enableOrDisableSubmitBtn(true);
    } else {
      this.renderRecaptcha();
      recaptchaFormTokenInput.setAttribute("value", null);
      this.enableOrDisableSubmitBtn(false);
    }
  }

  enableOrDisableSubmitBtn(enable) {
    const recapchaVerifiedOrHasToken = this.recaptchaTokenInput.dataset.verifiedRecaptcha == "true"
                                          || this.recaptchaTokenInput.getAttribute("value") !== null;
    if (enable && recapchaVerifiedOrHasToken) {
      this.submitBtn.classList.remove('disabled');
    } else {
      this.submitBtn.classList.add('disabled');
    }
  }

  ensureUniqueEditorInputName() {
    const editorInputId = this.editorInput.id;
    const existingEditorInputCount = document.querySelectorAll("[name='comment[content]']").length;
    const modifiedEditorInputId = `${editorInputId}_${ existingEditorInputCount }`;
    this.editorInput.id = modifiedEditorInputId;
    this.contentEditor.setAttribute("input", modifiedEditorInputId);
  }

  renderRecaptcha() {
    grecaptcha.ready(() => {
      grecaptcha.render(this.element.querySelector('.recaptcha-container'), {
        sitekey: this.recaptchaTokenInput.dataset.siteKey,
        callback: (token) => {
          this.recaptchaTokenInput.setAttribute("value", token);
          this.checkRecaptcha();
        },
        expiredCallback: () => {
          this.recaptchaTokenInput.setAttribute("value", null);
          this.checkRecaptcha();
        }
      });
    });
  }
}
