import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["replyComments"];

  connect() {
    Array.from(this.element.getElementsByClassName("toggleReplies")).forEach(link => {
      link.addEventListener('click', (event) => this.toggleReplies(event, link));
    });

    Array.from(this.element.getElementsByClassName("see-more")).forEach(seeMore => {
      seeMore.addEventListener('click', (event) => this.seeMore(event, seeMore));
    });

    Array.from(this.element.getElementsByClassName("nav-btn")).forEach(link => {
      link.addEventListener('click', () => this.scrollToCommentsTop())
    })
  }

  toggleReplies(event, link) {
    event.preventDefault();

    const commentId = link.dataset.commentId;

    const comment = document.getElementById(commentId.toString());
    const replyComments = comment.querySelector(".replyComments");

    if (Array.from(replyComments.classList).includes('hidden')) {
      replyComments.classList.remove('hidden');
    } else {
      replyComments.classList.add('hidden');
    }
  }

  seeMore(event, link) {
    event.preventDefault();

    const commentId = link.dataset.commentId;
    const comment = document.getElementById(commentId.toString());

    comment.querySelector('.full-text').classList.remove('hidden');
    comment.querySelector('.truncated-text').classList.add('hidden');
  }

  scrollToCommentsTop() {
    this.element.scrollIntoView({ behavior: "smooth" });
  }
}
