import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.element.addEventListener('submit', this.search.bind(this));
  }

  search(event) {
    event.preventDefault();

    const query = this.inputTarget.value.trim();
    if (!query) return;

    let url = this.filterUrlParams('query');
    location.href = this.addToUrlParams(url, 'query', query);
  }

  clear() {
    const query = this.inputTarget.value.trim();
    if (!query) return;

    let url = this.filterUrlParams('query');
    location.href = url;
  }

  filterUrlParams(key) {
    let url = location.href;
    let index = url.indexOf(key);

    if (index !== -1) {
      let suburl = url.substr(index)
                      .split("&")
                      .filter((value, index) => index !== 0)
                      .join('&');

      url = url.substr(0, index).replace(/&$/, "") + suburl;
    }

    return url;
  }

  addToUrlParams(url, key, value) {
    const separator = url.includes("?") ? "&" : "?";
    return `${url}${separator}${key}=${value}`.replace(/&&/, "&");
  }
}
