import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["menu"];
  static values = {
    hidden: Boolean
  };

  connect() {
    this.overlay = this.element.querySelector('.dropdown-overlay');
    this.overlay.addEventListener('click', this.toggle.bind(this));
  }

  toggle(event) {
    event.preventDefault();

    if (this.hiddenValue) {
      this.menuTarget.classList.remove("hidden");
      this.overlay.classList.add('fixed');
    } else {
      this.menuTarget.classList.add("hidden");
      this.overlay.classList.remove('fixed');
    }

    this.hiddenValue = !this.hiddenValue;
  }
}
