import { Controller } from "stimulus"
import Typed from "typed.js"

export default class extends Controller {
  static targets = ["typed"];

  connect() {
    const titles = [
      '',
      'A Software Engineer',
      'A Ruby Engineer',
      'A Tech Blogger',
      'A Tech Enthusiast',
    ];

    const options = {
      strings: titles.map(title => `<h1 class="text-blue-300 md:text-5xl font-semibold">${title}</h1>`),
      typeSpeed: 40,
      loop: true,
      showCursor: false
    }

    new Typed(this.typedTarget, options);
  }
}
